import AiMessage from "@/ai-assistant/ai-message";
import AiSession from "@/ai-assistant/ai-session";
import { makeApiInstance } from "@/api/instance";
import { useMutation, UseMutationOptions } from "@tanstack/vue-query";

const api = makeApiInstance({ baseURL: "/api/v1/ai_sessions" });

const useCreateAiSession = (options: UseMutationOptions<AiSession> = {}) => {
  return useMutation<AiSession>({
    ...options,
    mutationFn: () => api.post("").then((res) => res.data),
  });
};

type UseCreateAiMessageArgs = {
  session_id: string;
  message: string;
};

const useCreateAiMessage = (options: UseMutationOptions<AiMessage, Error, UseCreateAiMessageArgs> = {}) => {
  return useMutation<AiMessage, Error, UseCreateAiMessageArgs>({
    ...options,
    mutationFn: (args: UseCreateAiMessageArgs) => api.post("/messages", args).then((res) => res.data),
  });
};

export { useCreateAiSession, useCreateAiMessage, UseCreateAiMessageArgs };
