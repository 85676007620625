<template>
  <div class="report-actions" :class="{ 'new-layout': newLayout }">
    <div v-if="enabledComponents.length > 0" class="components-row">
      <component
        v-for="(component, index) in enabledComponents"
        :key="index"
        :is="component.component"
        v-bind="component.props"
      />
    </div>
    <dropdown v-if="enabledComponents.length > 0" ref="dropdown" class="show-new-layout hidden visible-xs visible-sm">
      <template v-slot:toggle="toggleProps">
        <button class="btn btn-default btn-dark-new-layout btn-sm btn-icon" @click="toggleProps.toggleDropdown">
          <icon icon="fluent:more-16-filled" class="text-base" />
        </button>
      </template>
      <template v-slot:menu>
        <component
          v-for="(component, index) in enabledComponents"
          :key="index"
          :is="component.component"
          v-bind="component.props"
        />
      </template>
    </dropdown>
  </div>
</template>

<script lang="ts" setup>
import ShareButton from "./share/share-button.vue";
import FavoriteReportButton from "./favorite-report-button.vue";
import SaveAsButton from "./save-as-button.vue";
import EditReportButton from "./edit-report-button.vue";
import DuplicateReportButton from "./duplicate-report-button.vue";
import DeleteReportButton from "./delete-report-button.vue";
import { Icon } from "@iconify/vue2";
import { computed, ref } from "vue";
import { useStore } from "@/composables/store";
import Dropdown from "@/components/dropdown.vue";
import SaveReportButtonOld from "@/components/save-report-button-old.vue";

type ComponentDefinition = {
  component: any;
  props: any;
};

const store = useStore();

const isAdmin = window.zoinedContext.isAdmin;

const company = window.zoinedContext.company;

const newLayout = window.zoinedContext.newLayout;

const dropdown = ref<typeof Dropdown>(null);

const reportContext = computed(() => {
  return store.getters.getReportContext;
});

const reportType = computed(() => {
  return reportContext.value?.report_type;
});

const report = computed(() => {
  return reportContext.value?.report;
});

const myReport = computed(() => {
  return reportContext.value?.my_report;
});

const enabledComponents = computed<ComponentDefinition[]>(() => {
  const components = [];
  if (!newLayout && report.value && !report.value.custom) {
    components.push({ component: SaveAsButton, props: {} });
  }
  if (myReport.value?.editable && !newLayout) {
    components.push({ component: SaveReportButtonOld, props: {} });
  }
  if (myReport.value?.editable && myReport.value?.id) {
    components.push({ component: EditReportButton, props: {} });
  }
  if ((newLayout || report.value?.custom) && myReport.value?.duplicatable) {
    components.push({ component: DuplicateReportButton, props: {} });
  }
  if (myReport.value?.removable) {
    components.push({ component: DeleteReportButton, props: {} });
  }
  if ((reportType.value == "system_dashboard" || myReport.value?.dashboard) && !company.licenses.limited) {
    components.push({ component: FavoriteReportButton, props: {} });
  }
  if (report.value && isAdmin) {
    components.push({ component: ShareButton, props: { report: report.value, myReport: myReport.value } });
  }
  return components;
});
</script>

<style lang="scss" scoped>
$grid-float-breakpoint: 992px;
.report-actions {
  .components-row {
    display: flex;
    align-items: flex-end;
    gap: 5px;
  }
  &.new-layout > .components-row {
    display: none;
    @media (min-width: $grid-float-breakpoint) {
      display: flex;
      align-items: flex-end;
      gap: 5px;
    }
  }
}
</style>
