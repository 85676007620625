<template lang="pug">
#sidenav(:class="{ open, expanded: showNavExpanded }")
  .overlay(:class="{ open, expanded: showNavExpanded }", @click="open = false")
  .sidenav-container(:class="{ open, expanded: showNavExpanded }")
    .sidenav-iconbar
      .logo-container
        router-link(v-if="hasRouterEnabled", to="/", v-uiv-tooltip="defaultDashboardTooltip")
          svg-icon.zoined-logo(name="zoined-logo")
        a(v-else, href="/")
          svg-icon.zoined-logo(name="zoined-logo")
      icon-btn.tour-dashboards-analytics(
        :active="tab == 'SidenavAnalytics'",
        :title="'layouts.navigation.analytics' | i18n",
        @click="toggleTab('SidenavAnalytics')"
      )
        svg-icon(name="analytics-library")
      .divider(v-if="showNewAnalytics")
      icon-btn(
        v-if="showNewAnalytics",
        :active="tab == 'SidenavNewAnalytics'",
        :title="'analytics.index.new_analytics' | i18n",
        @click="toggleTab('SidenavNewAnalytics')"
      )
        svg-icon(name="new-analytics")
      .divider(v-if="hasUserCompanyRole")
      icon-btn(
        v-if="hasUserCompanyRole",
        :active="tab == null && routePath == subscriptionsPath",
        :title="'newsletter.subscription.email_reports' | i18n",
        @click="openSubscriptions()"
      )
        svg-icon(name="mail")
      .divider(v-if="showSettings")
      icon-btn(
        v-if="showSettings"
        :active="tab == 'SidenavSettings'",
        :title="'dashboard_custom.report_editor.settings' | i18n",
        @click="toggleTab('SidenavSettings')"
      )
        svg-icon(name="settings")
      .expand-btn-container(v-if="tab")
        a.expand-btn(@click="onExpandBtnClicked")
          svg-icon(
            :name="expanded ? 'arrow-left-circle' : 'arrow-right-circle'",
            v-uiv-tooltip.right="expandButtonTooltip"
          )
    .results-container(:class="{ open, expanded: showNavExpanded }")
      .results
        .header.flex-row.justify-content-space-between.align-items-flex-start
          component.results-logo-container(:is="hasRouterEnabled ? 'router-link' : 'a'" to="/" href="/")
            .zoined-logo
              .zoined zoined
              .font-weight-md.font-size-md.uppercase Analytics
        keep-alive
          component(:is="tab")
</template>

<script lang="ts">
import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import IconBtn from "./icon-btn.vue";
import SvgIcon from "../components/svg-icon.vue";
import i18n from "../i18n";
import SidenavAnalytics from "./sidenav-analytics.vue";
import SidenavNewAnalytics from "./sidenav-new-analytics.vue";
import SidenavSettings from "./sidenav-settings.vue";
import { isReportActive } from "./utils";

const EXPANDED_COOKIE_NAME = "_zoined_sidenav_expanded";

@Component({
  components: {
    IconBtn,
    SvgIcon,
    SidenavAnalytics,
    SidenavNewAnalytics,
    SidenavSettings,
  },
})
export default class Sidenav extends Vue {
  tab: string = null;

  openCategory = null;

  open = false;

  expanded = true;

  get hasRouterEnabled() {
    return !!this.$route;
  }

  get routePath() {
    return this.$route?.fullPath || location.pathname;
  }

  get subscriptionsPath() {
    return "/newsletter_subscriptions";
  }

  get expandButtonTooltip() {
    return this.expanded ? i18n.t("sidenav.collapse_menu") : i18n.t("sidenav.expand_menu");
  }

  get defaultDashboardTooltip() {
    return i18n.t("dashboard_custom.default_dashboard");
  }

  get showNavExpanded() {
    return this.expanded && this.tab;
  }

  get showSettings() {
    return this.$store.state.navigation.data?.settings;
  }

  get showNewAnalytics() {
    return !!this.$store.state.navigation.data?.new_analytics;
  }

  get hasUserCompanyRole() {
    return !!window.zoinedContext.current_role;
  }

  get activeReport() {
    const analytics = this.$store.state.navigation.data?.analytics || [];

    const allReports = _.flatten(
      analytics.map((category) => _.flatten(category.folders.map(({ reports }) => reports)))
    );

    return allReports.find((report: any) => isReportActive(report));
  }

  get activeNewReport() {
    const newAnalytics = this.$store.state.navigation.data?.new_analytics || [];

    const allReports = _.flatten(
      newAnalytics.map((category) => _.flatten(category.folders.map(({ reports }) => reports)))
    );

    return allReports.find((report: any) => isReportActive(report));
  }

  get activeSettings() {
    const settings = this.$store.state.navigation.data?.settings || [];

    const allReports = _.flatten(settings.map((category) => _.flatten(category.folders.map(({ reports }) => reports))));

    return allReports.find((report: any) => isReportActive(report));
  }

  openSubscriptions() {
    this.tab = null;
    this.open = false;
    if (!this.$router) {
      // Angular
      location.href = this.subscriptionsPath;
    } else if (this.$route.path !== this.subscriptionsPath) {
      this.$router.push(this.subscriptionsPath);
    }
  }

  toggleTab(tab) {
    if (tab === this.tab) {
      this.open = !this.open;
    } else {
      this.tab = null;
      this.open = true;
      this.$nextTick(() => {
        this.tab = tab;
      });
    }
  }

  onExpandBtnClicked() {
    this.expanded = this.open = !this.expanded;
    window.setCookie(EXPANDED_COOKIE_NAME, this.expanded ? "yes" : "no");
  }

  @Watch("routePath")
  onRouteChange() {
    this.open = false;
  }

  created() {
    this.expanded = window.getCookie(EXPANDED_COOKIE_NAME) !== "no";

    this.setCurrentTab();
  }

  setCurrentTab() {
    if (this.activeReport) {
      this.tab = "SidenavAnalytics";
    } else if (this.activeNewReport) {
      this.tab = "SidenavNewAnalytics";
    } else if (this.activeSettings) {
      this.tab = "SidenavSettings";
    }
  }
}
</script>
<style lang="scss" scoped>
$grid-float-breakpoint: 992px;

.overlay {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: var(--z-index-sidenav-overlay);

  &.open {
    display: block;

    @media (min-width: 1200px) {
      &.expanded {
        display: none;
      }
    }
  }
}

.sidenav-container {
  background-color: var(--color-sidenav-background);
  color: var(--color-sidenav-text);
  z-index: var(--z-index-sidenav);
  overflow: hidden;

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;

  height: var(--sidenav-iconbar-width);
  transition: height 0.2s ease 0.1s;

  &.open {
    height: 100%;
    transition: height 0.2s ease 0s;
  }

  .sidenav-iconbar {
    display: flex;
    height: var(--sidenav-iconbar-width);
    width: 100%;
    justify-content: flex-start;
    column-gap: 20px;
    padding: 0 10px;

    .logo-container {
      display: none;
    }

    .expand-btn-container {
      display: none;

      .expand-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        color: white;
        font-size: 24px;
        font-weight: normal;
      }
    }

    .divider {
      display: none;
    }
  }

  .results-container {
    flex: 1;
    overflow: auto;

    .header {
      margin-top: 25px;
      margin-bottom: 20px;
      padding: 0 15px;

      .results-logo-container {
        .zoined-logo {
          color: white;

          .zoined {
            font-size: 28px;
            font-weight: bold;
            line-height: 1;
          }
        }
      }
    }

    .results {
      opacity: 0;
      transition: opacity 0.2s ease 0s;
    }

    &.open .results,
    &.expanded .results {
      opacity: 1;
      transition: opacity 0.2s ease 0.1s;
    }
  }

  @media (min-width: $grid-float-breakpoint) {
    top: 0;
    left: 0;
    bottom: 0;
    height: auto;
    width: auto;
    flex-direction: row;
    justify-content: flex-start;

    .sidenav-iconbar {
      flex-direction: column;
      width: var(--sidenav-iconbar-width);
      height: 100%;
      justify-content: flex-start;
      padding: 0;

      .logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 25px;
        margin-bottom: 20px;
      }

      .divider {
        display: block;
        margin: 10px 15px;
        border-bottom: 2px solid var(--color-sidenav-icon-btn-background);
      }
    }

    .results-container {
      width: 0px;
      height: 100%;
      flex: none;
      transition: width 0.2s ease 0.1s;

      &.open {
        width: var(--sidenav-navbar-width);
        transition: width 0.2s ease 0s;
      }
    }
  }

  @media (min-width: 1200px) {
    .sidenav-iconbar {
      .expand-btn-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
    }

    .results-container {
      &.expanded {
        width: var(--sidenav-navbar-width);
      }
    }
  }

  @media print {
    display: none;
  }
}
</style>
