<template>
  <div class="report-action-button inline-block" :class="{ 'new-layout': newLayout }" @click="onClick">
    <button
      type="button"
      class="btn btn-default btn-dark-new-layout btn-sm btn-icon flex-row justify-content-center align-items-center"
      :disabled="disabled"
    >
      <icon :icon="icon" class="text-base" />
    </button>
    <button
      type="button"
      class="btn btn-tertiary btn-sm show-new-layout hidden visible-xs visible-sm"
      :disabled="disabled"
      @click="onClick"
    >
      <div class="flex-row align-items-center gap-sm">
        <icon :icon="icon" class="text-base" />
        <span v-if="text">{{ text }}</span>
      </div>
    </button>
  </div>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue2";

defineProps<{
  icon: string;
  disabled?: boolean;
  text?: string;
}>();

const emit = defineEmits(["click"]);

const newLayout = window.zoinedContext.newLayout;

const onClick = (evt) => {
  emit("click", evt);
};
</script>

<style scoped lang="scss">
$grid-float-breakpoint: 992px;

.report-action-button.new-layout {
  button.btn-icon {
    display: none;
  }
  @media (min-width: $grid-float-breakpoint) {
    button.btn-sm {
      height: 35px;

      &.btn-icon {
        display: flex;
        width: 35px;
      }
    }
  }
}
</style>
