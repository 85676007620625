<template>
  <div class="ai-assistant-panel flex-column">
    <div class="drag-handle mb-md" @click="toggleOpen">
      <div class="drag-handle-bar"></div>
    </div>
    <div class="content-container flex-1 min-h-0" :class="{ open }">
      <div class="content flex-column">
        <div class="flex-row justify-content-center bg-surface-emphasis-primary-1 rounded p-md relative">
          <button class="btn btn-sm btn-tertiary active flex-row align-items-center gap-sm">
            <icon icon="fluent:wand-16-filled" />
            {{ t("actions.create") }}
          </button>
          <button class="close-button btn btn-icon btn-sm btn-primary hidden-xs hidden-sm" @click="toggleOpen">
            <icon icon="fluent:dismiss-16-filled" />
          </button>
        </div>
        <div class="ai-messages flex-1 fg-text-variant flex-column gap-xxl min-h-0 overflow-y-scroll scrollbar-hidden">
          <div>
            In this space you can create, save and edit your analytics with the help of Zed.
          </div>
          <ai-message-view
            :ai-message="aiMessage"
            v-for="(aiMessage, index) in aiMessages"
            :key="index"
          ></ai-message-view>
        </div>
      </div>
    </div>
    <form @submit="onSubmit">
      <input type="text" placeholder="Ask Zed" :disabled="isProcessing" v-model="message" @focus="onInputFocus" />
    </form>
  </div>
</template>

<script setup lang="ts">
import { useCreateAiMessage, useCreateAiSession } from "@/ai-assistant/ai-assistant-api";
import AiMessage from "@/ai-assistant/ai-message";
import AiSession from "@/ai-assistant/ai-session";
import { computed, ref, watch } from "vue";
import { Icon } from "@iconify/vue2";
import { useStore } from "@/composables/store";
import Mutations from "@/store/mutations";
import { useRoute, useRouter } from "vue-router/composables";
import { useI18n } from "@/composables/i18n";
import AiMessageView from "@/ai-assistant/ai-message-view.vue";
import { useIsMobile } from "@/composables/is-mobile";
import FilterConfiguration from "@/model/filter-configuration";
import { availableComponents } from "@/custom-dashboard-editor/component-types";
import { flatFilterConfiguration } from "@/lib/filter-util";

const message = ref("");

const session = ref<AiSession>(null);

const route = useRoute();

const router = useRouter();

const store = useStore();

const { t } = useI18n();

const aiMessages = computed(() => store.state.aiAssistant.messages);

const createSessionMutation = useCreateAiSession();

const createMessageMutation = useCreateAiMessage();

const isMobile = useIsMobile();

const createMessage = () => {
  if (message.value.trim().length === 0) {
    return;
  }

  store.commit(Mutations.addAiMessage, { session_id: null, message: message.value });

  const _doCreateSession = () => {
    createMessageMutation.mutate(
      { session_id: session.value.session_id, message: message.value },
      {
        onSuccess: (aiMessage: AiMessage) => {
          message.value = "";
          store.commit(Mutations.amendAiMessage, aiMessage);
          if (aiMessage.answer) {
            addComponent(aiMessage.answer);
          }
          if (route.name !== "zed_workspace") {
            router.push({ name: "zed_workspace" });
          }
          if (isMobile.value) {
            setTimeout(() => {
              if (open) {
                toggleOpen();
              }
            }, 2000);
          }
        },
      }
    );
  };

  if (!session.value) {
    createSessionMutation.mutate(null, {
      onSuccess: (data) => {
        session.value = data;
        _doCreateSession();
      },
    });
  } else {
    _doCreateSession();
  }
};

const isProcessing = computed(() => createSessionMutation.isPending.value || createMessageMutation.isPending.value);

const open = computed(() => store.state.aiAssistant.open);

const onSubmit = async (event: Event) => {
  event.preventDefault();

  createMessage();
};

const onInputFocus = () => {
  if (!open.value) {
    toggleOpen();
  }
};

const toggleOpen = () => {
  store.commit(Mutations.toggleAiAssistant);
};

const addComponent = ({ chart, filterConfiguration }: { chart: string; filterConfiguration: FilterConfiguration }) => {
  const componentType = getComponentType(chart);
  const component = getComponent(componentType, filterConfiguration);
  const components = [...store.state.aiAssistant.components, component];
  store.commit(Mutations.setAiComponents, components);
};

const getComponentType = (chart: string) => {
  switch (chart) {
    case "pie":
      return "pie";
    case "line":
      return "line";
    case "bar":
    case "column":
      return "dual_axis";
  }
};

const getComponent = (componentType, filterConfiguration) => {
  const baseConfiguration = availableComponents[componentType];
  filterConfiguration = flatFilterConfiguration(filterConfiguration);

  return {
    ...baseConfiguration,
    filterConfiguration,
  };
};
</script>

<style lang="scss" scoped>
.ai-assistant-panel {
  // background-color: var(--color-surface-neutral-1);
  background-color: white;
  width: 100%;
  height: auto;
  max-height: 70vh;
  padding: 5px 15px 15px 15px;
  border-top-left-radius: var(--border-radius-md);
  border-top-right-radius: var(--border-radius-md);
  box-shadow: 0px -2px 20px 0px rgba(217, 217, 217, 0.5);

  .drag-handle {
    display: flex;
    justify-content: center;
    padding: 5px 0;

    .drag-handle-bar {
      width: 40px;
      height: 5px;
      border-radius: 5px;
      background-color: var(--color-border-variant-2);
    }

    @media (min-width: 992px) {
      display: none;
    }
  }

  .content-container {
    overflow: hidden;
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.25s ease;

    &.open {
      grid-template-rows: 1fr;
    }

    .content {
      overflow: hidden;
    }

    @media screen and (min-width: 992px) {
      display: flex;
      flex-direction: column;

      overflow: visible;

      .content {
        flex: 1;
        min-height: 0;
        overflow: visible;
        padding-bottom: 15px;
      }
    }
  }

  .ai-messages {
    padding: 10px 15px;

    @media screen and (min-width: 992px) {
      padding: 10px 0;
    }
  }

  input {
    border: none;
    box-shadow: none !important;
    outline: none !important;
    width: 100%;
    max-width: unset;
    background: var(--color-surface-neutral-2);
    color: var(--color-text-variant);
    font-size: 14px;
    border-radius: var(--border-radius-sm);

    &:focus,
    &:not(:placeholder-shown) {
      background-color: transparent;
      border: 1px solid var(--color-border-emphasis);
    }
  }

  button.close-button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%) translateX(50%);
  }

  @media (min-width: 992px) {
    height: 100%;
    max-height: unset;
    padding: 15px;
    border-bottom-left-radius: var(--border-radius-md);
    border-bottom-right-radius: var(--border-radius-md);
  }
}
</style>
