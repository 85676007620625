<template lang="pug">
div
  #layout-container.custom-dashboard-editor
    .row
      .col-md-4
        .form-group
          label(for="name") {{ "dashboard_custom.report_editor.title" | i18n }}
          input#name(type="text", v-model="report.metadata.title")
      .col-md-8
        .form-group
          label(for="description") {{ "dashboard_custom.report_editor.description" | i18n }}
          textarea#description(v-model="report.metadata.description")
    .row(v-if="isAdmin || isTeamAdmin")
      .col-md-12
        report-owner-selector(:my-report="myReport", @update-my-report="updateMyReport")
    .row(v-if="companyReport")
      .col-md-12
        .form-group
          div
            label(for="restricted") {{ "activerecord.attributes.my_report.restrict_access" | i18n }}
          .btn-group
            .btn.btn-primary(:class="{ active: myReport.restrict_access }", @click="setRestricted(true)") Yes
            .btn.btn-primary(:class="{ active: !myReport.restrict_access }", @click="setRestricted(false)") No
    .row(v-if="companyReport && myReport.restrict_access")
      .col-md-12
        user-role-pill-list(:roles="permittedRoles", @update="updatePermittedRoles($event)")
        team-pill-list(:teams="permittedTeams", @update="updatePermittedTeams($event)")
    .row(v-if="partnerReport")
      .col-md-12
        visible-to-customers-selector(v-model="myReport.visible_to_customers")

    .row
      .col-md-12
        h4 {{ "dashboard_custom.report_editor.filters" | i18n }}

    .row.time-filters
      .col-md-12
        time-period-dropdown(
          :available-items="options.time_ranges",
          :available-series-types="options.seriesTypes",
          :value="filterConfiguration.time.selection",
          @update="updateSelection($event)"
        )
        comparison-pill-list(:comparisons="filterConfiguration.time.comparisons", @update="updateComparisons")
        business-hours-selector(
          :value="filterConfiguration.time.limit_to_business_hours",
          @update="updateBusinessHours($event)"
        )

    .row
      .col-md-12
        filterset-selector(:config="filterConfiguration.filters", @update="updateFiltersConfig")
        show-filterset-name-toggle(v-model="myReport.control_state.show_filterset_name", v-if="hasEnabledFilterSets")
    .row
      .col-md-12
        .filter-container
          filter-selector(
            :config="filters[filter.id]",
            :filter="filter",
            @update="updateFilter(filter.id, $event)",
            v-for="filter in options.filters",
            :key="filter.id"
          )

    .row
      .col-md-12
        h4 {{ "dashboard_custom.report_editor.layout" | i18n }}

    dashboard-components-grid-editor(
      :filter-configuration="filterConfiguration",
      :config="report.config",
      :chart-options="myReport.control_state",
      :options="options",
      :shared="companyReport || partnerReport",
      @update="report.config = $event"
    )

    .row.buttons
      .col-md-12
        .pull-left.hide-new-layout
          confirm-button.delete-dashboard(
            v-if="report.id && myReport.removable",
            :button-title="'dashboard_custom.report_editor.delete_dashboard' | i18n",
            variant="danger",
            @confirm="deleteDashboard()"
          )
        .pull-right
          button.pull-right.btn.btn-primary(@click="save", :disabled="submitDisabled") {{ "dashboard_custom.report_editor.save" | i18n }}
</template>

<script lang="ts">
import Vue from "vue";
import _ from "lodash";
import {
  refreshFilterTimes,
  convertToNewFilterConfiguration,
  filterToFlyover,
  mergeFilterConfigurations,
} from "../lib/filter-util";
import confirmButton from "../components/confirm-button.vue";
import userRolePillList from "../components/user-role-pill-list.vue";
import teamPillList from "../components/team-pill-list.vue";
import comparisonPillList from "../components/comparison-pill-list.vue";
import filterSelector from "../components/filter-selector.vue";
import timePeriodDropdown from "../components/time-period-dropdown.vue";
import businessHoursSelector from "../components/business-hours-selector.vue";
import filtersetSelector from "../components/filterset-selector.vue";
import showFiltersetNameToggle from "../components/show-filterset-name-toggle.vue";
import DashboardComponentsGridEditor from "./dashboard-components-grid-editor.vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import FilterConfigurationItemMap from "../model/filter-configuration-item-map";
import MyReport from "../model/my-report";
import ReportOwnerSelector from "../components/report-owner-selector.vue";
import Actions from "../store/actions";
import i18n from "../i18n";
import { makeApiInstance } from "../api/instance";
import { hideOverlay, showOverlay } from "../ui/overlay";
import VisibleToCustomersSelector from "@/components/visible-to-customers-selector.vue";

@Component({
  components: {
    confirmButton,
    userRolePillList,
    teamPillList,
    comparisonPillList,
    filterSelector,
    timePeriodDropdown,
    businessHoursSelector,
    filtersetSelector,
    showFiltersetNameToggle,
    DashboardComponentsGridEditor,
    ReportOwnerSelector,
    VisibleToCustomersSelector,
  },
})
export default class CustomDashboardEditor extends Vue {
  @Prop()
  defaultReport: any;

  @Prop()
  defaultMyReport: any;

  report: any = null;
  myReport: any = null;
  permittedRoles: any = null;
  permittedTeams: any = null;
  isAdmin: boolean = null;
  initialData = null;

  get companyReport() {
    return !this.myReport.user_id && !this.myReport.team_id && !this.myReport.partner_id;
  }

  get partnerReport() {
    return !!this.myReport.partner_id;
  }

  get teamsAsAdmin() {
    return window.zoinedContext.current_role?.team_memberships.filter(({ role }) => role === "admin") || [];
  }

  get isTeamAdmin() {
    return this.teamsAsAdmin.length > 0;
  }

  get submitDisabled() {
    return this.report.metadata.title.length == 0 || this.report.config.components.length == 0;
  }

  get filterConfiguration() {
    return _.cloneDeep(this.myReport.filters);
  }

  get options() {
    const sort = this.$store.getters.getParameters("sort") || [];
    const seriesTypes = window.zoinedContext?.budgets && ["actual", ...Object.keys(window.zoinedContext.budgets)];
    const groupings = _.clone(this.$store.getters.getParameters("grouping"));
    const metrics = this.$store.state.parameters.metrics.all;
    const snippets = this.$store.state.parameters.metrics.all?.map((item) => ({ ...item, snippet: true }));
    const filters = this.$store.state.parameters.filters.all;
    const time_ranges = this.$store.state.parameters.timePeriods.all;

    return {
      metrics: metrics || [],
      snippets: snippets || [],
      time_ranges: time_ranges || [],
      filters: filters || [],
      sort,
      seriesTypes,
      groupings,
    };
  }

  get filters() {
    return _.reduce(
      this.filterConfiguration.filters.filters,
      (result, config, filter) => {
        return {
          ...result,
          [filter]: _.fromPairs(
            _.map(config, ({ enabled, exclude, value, name }, key) => [
              key,
              {
                value: key,
                enabled,
                exclude: !!exclude,
                name: name || value || key,
              },
            ])
          ),
        };
      },
      {}
    );
  }
  get hasEnabledFilterSets() {
    let { sets } = this.filterConfiguration.filters;
    sets = sets || [];
    return sets.filter(({ enabled }) => enabled).length > 0;
  }

  get saveData() {
    return _.cloneDeep({
      report: {
        ...this.report,
        config: JSON.stringify(this.report.config),
      },
      my_report: {
        dashboard: true,
        restrict_access: this.myReport.restrict_access,
        visible_to_customers: this.myReport.visible_to_customers,
        control_state: this.myReport.control_state,
        permitted_role_ids: this.myReport.restrict_access ? this.permittedRoles.map(({ id }) => id) : [],
        permitted_team_ids: this.myReport.restrict_access ? this.permittedTeams.map(({ id }) => id) : [],
        user_id: this.myReport.user_id,
        team_id: this.myReport.team_id,
        partner_id: this.myReport.partner_id,
        company_id: this.myReport.company_id,
        filters: this.filterConfiguration,
      },
    });
  }

  get hasChanges() {
    return this.initialData && !_.isEqual(this.initialData, this.saveData);
  }

  setRestricted(restricted) {
    Vue.set(this.myReport, "restrict_access", restricted);
  }

  updateSelection(selection) {
    Vue.set(this.myReport.filters.time, "selection", selection);
  }

  updateComparisons(comparisons) {
    Vue.set(this.myReport.filters.time, "comparisons", comparisons);
  }

  updateBusinessHours(limitToBusinessHours) {
    Vue.set(this.myReport.filters.time, "limit_to_business_hours", limitToBusinessHours);
  }

  updateFiltersConfig(config) {
    Vue.set(this.myReport.filters, "filters", config);
  }

  updateFilter(filter, filterConfig: FilterConfigurationItemMap) {
    if (!_.isEmpty(filterConfig)) {
      const config = _.reduce(
        _.values(filterConfig),
        (result, { enabled, exclude, value, name, wildcard }) => ({
          ...result,
          [value]: { enabled, exclude: !!exclude, value, wildcard, name: name || value },
        }),
        {}
      );
      Vue.set(this.myReport.filters.filters.filters, filter, config);
    } else {
      Vue.delete(this.myReport.filters.filters.filters, filter);
    }
  }

  updatePermittedRoles(roles) {
    this.permittedRoles = roles;
  }

  updatePermittedTeams(teams) {
    this.permittedTeams = teams;
  }

  updateMyReport(myReport: MyReport) {
    Vue.set(this, "myReport", myReport);
  }

  save() {
    showOverlay(i18n.t("please_wait"));
    const url = this.report.id ? `/api/v1/reports/${this.report.id}` : `/api/v1/reports`;
    const method = this.report.id ? "PUT" : "POST";
    const data = (this.initialData = this.saveData);
    return makeApiInstance()
      .request({
        url,
        method,
        data,
      })
      .then((response) => response.data)
      .then(({ id }) => {
        this.$router.push({ name: "custom_dashboard", params: { id } });
        this.$store.dispatch(Actions.fetchNavigation);
      })
      .finally(() => {
        hideOverlay();
      });
  }

  deleteDashboard() {
    const url = `/api/v1/reports/${this.report.id}`;
    return makeApiInstance()
      .delete(url)
      .then(() => {
        window.location.href = "/";
      });
  }

  created() {
    const report = _.cloneDeep(this.defaultReport);
    const myReport = _.cloneDeep(this.defaultMyReport);
    const permittedRoles = myReport.permitted_roles || [];
    const permittedTeams = myReport.permitted_teams || [];
    delete report.permitted_roles;

    const defaultConfig = {
      selection: { type: "4_weeks" },
      comparisons: [{ type: "prev_year_corresponding", enabled: true }],
    };
    const filterConfigurations = _.compact([
      defaultConfig,
      report.config.filterConfiguration,
      myReport.filters,
    ]).map((config) => convertToNewFilterConfiguration(config));
    myReport.filters = mergeFilterConfigurations(...filterConfigurations);
    myReport.filters.time = refreshFilterTimes(myReport.filters.time);
    delete report.config.filterConfiguration;

    report.config.default = {};
    report.config.components = report.config.components || [];

    myReport.restrict_access = !!myReport.restrict_access;
    myReport.control_state = myReport.control_state || {};

    this.report = report;
    this.myReport = myReport;
    this.isAdmin = !!window.zoinedContext?.isAdmin;

    this.permittedRoles = permittedRoles;
    this.permittedTeams = permittedTeams;

    this.initialData = this.saveData;
  }
}
</script>

<style scoped lang="scss">
#layout-container {
  margin-bottom: 8rem; // need to have space for the Olark-tab in bottom of the page
}

.buttons {
  margin-top: 15px;
}

.show {
  text-align: center;

  a:hover {
    cursor: pointer;
  }
}

.time-filters {
  margin-bottom: 10px;
}

.row h4 {
  margin: 20px 0;
}
</style>
