<template lang="pug">
uiv-modal.user-dialog(v-if="role", v-model="open", :title="title", :backdrop="false")
  spinner(v-if="saving")
  .row
    .col-md-12
      h4
        | {{ "attributes.name" | i18n }}
    .col-md-4
      label(for="edit_first_name") {{ "activerecord.attributes.user.first_name" | i18n }}
      input#edit_first_name.form-control(
        type="text",
        v-model="role.first_name",
        :placeholder="'activerecord.attributes.user.first_name' | i18n"
      )
    .col-md-6
      label.control-label(for="edit_last_name") {{ "activerecord.attributes.user.last_name" | i18n }}
      input#edit_last_name.form-control(
        type="text",
        v-model="role.last_name",
        :placeholder="'activerecord.attributes.user.last_name' | i18n"
      )
    .col-md-6
      .mt-md
        label.control-label(for="edit_email") {{ "activerecord.attributes.user.email" | i18n }}
        input#edit_email.form-control(
          type="email",
          v-model="role.email",
          :placeholder="'activerecord.attributes.user.email' | i18n"
        )
    .col-md-12(v-if="role.unconfirmed_email") {{ "activerecord.attributes.user.unconfirmed_email" | i18n }}: {{  role.unconfirmed_email }}
    .col-md-6
      .mt-md
        label.control-label(for="edit_title") {{ "activerecord.attributes.user.title" | i18n }}
        user-title-selector#edit_title(v-model="role.title")
  .row
    .col-md-12
      h4
        | {{ "profile.user_preferences" | i18n }}
        span.info-bubble(v-uiv-tooltip.right="userPreferencesTooltip")
    .col-md-3
      label(for="edit_role") {{ "activerecord.attributes.user.role" | i18n }}
      select#edit_role.form-control(v-model="role.role")
        option(:value="role.id", v-for="role in roleOptions") {{ role.name }}
    .col-md-3
      label(for="edit_language") {{ "activerecord.attributes.user.language" | i18n }}
      select#edit_language.form-control(v-model="role.language")
        option(:value="locale.id", v-for="locale in localeOptions") {{ locale.name }}
    .col-md-4
      label(for="edit_newsletter_currency") {{ "activerecord.attributes.user.newsletter_currency" | i18n }}
      select#edit_newsletter_currency.form-control(v-model="role.newsletter_currency")
        option(:value="currency.currency", v-for="currency in currencyOptions") {{ currency.label }}

  .row
    .col-md-12
      h4
        | {{ "filter.config.allowFileExport" | i18n }}
    .form-group
      .col-sm-9
        .allow-options
          .btn-group(data-toggle="buttons")
            label.btn.btn-primary(:class="{ active: !role.deny_export }", @click="updateExport(false)") {{ "permissions.allow" | i18n }}
            label.btn.btn-primary(:class="{ active: role.deny_export }", @click="updateExport(true)") {{ "permissions.deny" | i18n }}

  .row(v-if="teams.length > 0")
    .col-md-12
      h4
        | {{ "company_admin.teams.title" | i18n }}
        span.info-bubble(v-uiv-tooltip.right="teamsTooltip")
      teamMembershipTable(:team-memberships="role.team_memberships", :teams="teams", @update="updateTeamMemberships")

  .row
    .col-md-12
      h4
        | {{ "profile.user_permissions" | i18n }}
        span.info-bubble(v-uiv-tooltip.right="permissionsTooltip")
      permissions-editor(
        :permissions="role.permissions",
        :effective-permissions="role.effective_permissions",
        @update="updatePermissions"
      )

  .row(v-if="companyDashboards")
    .col-md-12
      h4
        | {{ "dashboard_custom.default_dashboard" | i18n }}
        span.info-bubble(v-uiv-tooltip="defaultDashboardTooltip")
      select#edit_default_dashboard.form-control(v-model="role.default_dashboard_id")
        option(:value="null") {{ 'dashboard.system_dashboard' | i18n }}
        option(:value="dashboard.report.id", v-for="dashboard in companyDashboards") {{ dashboard.name }}

  .row(v-if="restrictedDashboards.length > 0")
    .col-md-12
      h4
        | {{ "profile.allowed_restricted_dashboards" | i18n }}
        span.info-bubble(v-uiv-tooltip.right="allowedDashboardsTooltip")
      .permissions-row
        allowed-reports(
          :title="'dashboard_custom.dashboards' | i18n",
          :restricted-reports="restrictedDashboards",
          :allowed-reports="role.permitted_dashboards",
          @change="updatePermittedDashboards"
        )

  .row(v-if="restrictedReports.length > 0")
    .col-md-12
      h4
        | {{ "profile.allowed_restricted_reports" | i18n }}
        span.info-bubble(v-uiv-tooltip.right="allowedReportsTooltip")
      .permissions-row
        allowed-reports(
          :title="'analytics.reports' | i18n",
          :restricted-reports="restrictedReports",
          :allowed-reports="role.permitted_reports",
          @change="updatePermittedReports"
        )

  .row
    .col-md-12
      h4
        | {{ "profile.default_params" | i18n }}
        span.info-bubble(v-uiv-tooltip.right="defaultParametersTooltip")
      defaultParameterSelector(v-model="role.default_parameters", :permissions="role.effective_permissions")

  .row(v-if="definitions && definitions.length > 0")
    .col-md-12
      h4
        | {{ "newsletter.subscription.list_title" | i18n }}
        span.info-bubble(v-uiv-tooltip.right="newsletterSubscriptionsTooltip")
      subscriptions-selector(v-model="role.definition_ids", :definitions="definitions")
      p(style="padding-left: 5px", v-if="role.team_newsletter_subscriptions.length > 0") {{ "profile.team_subscriptions" | i18n }}: {{ teamNewsletterSubscriptionsText }}
  div(slot="footer")
    confirm-button.pull-left(@confirm="deleteUser", :button-title="'actions.delete' | i18n")
    button.btn(@click="cancel()") {{ "actions.cancel" | i18n }}
    button.btn.btn-primary(type="submit", @click="ok()" :disabled="saving") {{ "actions.save" | i18n }}
</template>

<script lang="ts">
import i18n from "../i18n";
import Vue from "vue";
import Component from "vue-class-component";
import Actions from "@/store/actions";
import { Prop } from "vue-property-decorator";
import adminCompaniesApi from "../api/admin-companies-api";
import adminUsersApi from "../api/admin-users-api";
import DashboardsApiService from "../api/dashboards-api-service";
import UserCompanyRole from "../model/user-company-role";
import { normalizeConfig } from "@/lib/filter-util";
import _ from "lodash";
import { calculateEffectivePermissions, defaultPermissions } from "@/permissions/utils";
import Team from "@/model/team";
import NewsletterDefinition from "@/model/newsletter-definition";
import TeamMembershipTable from "./team-membership-table.vue";
import FilterSelector from "@/components/filter-selector.vue";
import ConfirmButton from "@/components/confirm-button.vue";
import AllowedReports from "@/permissions/allowed_reports.vue";
import PermissionsEditor from "@/permissions/permissions-editor.vue";
import PillList from "@/components/pill-list.vue";
import DefaultParameterSelector from "./default-parameter-selector.vue";
import SubscriptionsSelector from "./subscriptions-selector.vue";
import Spinner from "@/components/spinner.vue";
import UserTitleSelector from "@/components/user-title-selector.vue";

@Component({
  components: {
    TeamMembershipTable,
    FilterSelector,
    ConfirmButton,
    AllowedReports,
    PermissionsEditor,
    PillList,
    DefaultParameterSelector,
    SubscriptionsSelector,
    Spinner,
    UserTitleSelector,
  },
})
export default class UserDialog extends Vue {
  @Prop()
  teams: Team[];

  @Prop()
  definitions: NewsletterDefinition[];

  open = false;
  restrictedDashboards: any[] = null;
  restrictedReports: any[] = null;
  companyDashboards: any[] = null;
  role: UserCompanyRole = null;
  originalRole: UserCompanyRole = null;
  saving = false;

  get title() {
    return `${this.originalRole.first_name} ${this.originalRole.last_name} - ${this.originalRole.email}`;
  }

  get userPreferencesTooltip() {
    return i18n.t("setup.users.tooltips.user_preferences");
  }

  get permissionsTooltip() {
    return i18n.t("setup.users.tooltips.permissions");
  }

  get defaultDashboardTooltip() {
    return i18n.t("setup.users.tooltips.default_dashboard");
  }

  get allowedDashboardsTooltip() {
    return i18n.t("setup.users.tooltips.allowed_dashboards");
  }

  get allowedReportsTooltip() {
    return i18n.t("setup.users.tooltips.allowed_reports");
  }

  get defaultParametersTooltip() {
    return i18n.t("setup.users.tooltips.default_parameters");
  }

  get newsletterSubscriptionsTooltip() {
    return i18n.t("setup.users.tooltips.newsletter_subscriptions");
  }

  get teamsTooltip() {
    return i18n.t("setup.users.tooltips.teams");
  }

  get companyId() {
    return window.zoinedContext.companyId;
  }

  get company() {
    return window.zoinedContext.company;
  }

  get currencyOptions() {
    return [
      { currency: "", label: i18n.t("activerecord.attributes.company.company_currency") },
      ...window.zoinedContext.currencies.map(({ currency, symbol }) => ({
        currency,
        label: `${currency} (${symbol})`,
      })),
    ];
  }

  get roleOptions() {
    return ["email", "user", "admin"].map((id) => {
      return { id, name: i18n.t(`roles.${id}`) };
    });
  }

  get localeOptions() {
    return this.$store.getters.getParameters("locales");
  }

  get availableFilters() {
    return this.$store.getters.getParameters("filters");
  }

  get teamNewsletterSubscriptionsText() {
    return this.role.team_newsletter_subscriptions.map(({ title }) => title).join(", ");
  }

  get userTeams() {
    return this.role.team_memberships.map(({ team_id }) => _.find(this.teams, (team) => team.id === team_id));
  }

  created() {
    this.$store.dispatch(Actions.fetchParameters("locales"));
    adminCompaniesApi.getRestrictedDashboards({ id: this.companyId }).then((r) => {
      this.restrictedDashboards = r;
    });

    adminCompaniesApi.getRestrictedReports({ id: this.companyId }).then((r) => {
      this.restrictedReports = r;
    });
  }

  setupRole(role: UserCompanyRole = null) {
    this.originalRole = role;
    this.role = _.cloneDeep(role);
    this.companyDashboards = null;

    new DashboardsApiService()
      .getCompanyDashboards(this.role.user_id)
      .then((dashboards) => (this.companyDashboards = dashboards));

    if (this.role.language === null) {
      this.role.language = "";
    }
    if (this.role.newsletter_currency === null) {
      this.role.newsletter_currency = "";
    }
    if (_.isEmpty(this.role.default_parameters)) {
      this.role.default_parameters = {};
    }
    this.role.default_parameters = normalizeConfig(this.role.default_parameters).filters;
    if (_.isEmpty(this.role.permissions)) {
      this.role.permissions = defaultPermissions();
    }
    Vue.set(
      this.role,
      "definition_ids",
      (this.role.newsletter_subscriptions || []).map((s) => s.id)
    );
  }

  ok() {
    const params: Partial<UserCompanyRole> = {};
    if (this.role.email != this.originalRole.email) {
      params.email = this.role.email;
    }
    if (this.role.first_name != this.originalRole.first_name) {
      params.first_name = this.role.first_name;
    }
    if (this.role.last_name != this.originalRole.last_name) {
      params.last_name = this.role.last_name;
    }
    if (this.role.role != this.originalRole.role) {
      params.role = this.role.role;
    }
    if (this.role.title != this.originalRole.title) {
      params.title = this.role.title;
    }
    if (this.role.language != this.originalRole.language) {
      params.language = this.role.language;
    }
    if (this.role.newsletter_currency != this.originalRole.newsletter_currency) {
      params.newsletter_currency = this.role.newsletter_currency;
    }
    if (!_.isEqual(this.role.permissions, this.originalRole.permissions)) {
      params.permissions = this.role.permissions;
    }
    if (!_.isEqual(this.role.default_parameters, this.originalRole.default_parameters)) {
      params.default_parameters = this.role.default_parameters;
    }
    const subs = _.sortBy(this.role.definition_ids);
    if (!_.isEqual(subs, _.sortBy(this.originalRole.newsletter_subscriptions.map((s) => s.id)))) {
      params.definition_ids = subs;
    }
    params.team_memberships = this.role.team_memberships;
    if (this.role.deny_export != this.originalRole.deny_export) {
      params.deny_export = this.role.deny_export;
    }

    if (this.role.default_dashboard_id != this.originalRole.default_dashboard_id) {
      params.default_dashboard_id = this.role.default_dashboard_id;
    }

    params.permitted_dashboard_ids = this.role.permitted_dashboards.map(({ id }) => id);
    params.permitted_report_ids = this.role.permitted_reports.map(({ id }) => id);

    if (!_.isEmpty(params)) {
      this.saving = true;
      adminUsersApi
        .put({ id: this.role.id, company_id: this.companyId, user: params })
        .then((role) => {
          this.$emit("updated", role);
          this.open = false;
        })
        .finally(() => {
          this.saving = false;
        });
    }
  }

  show(role: UserCompanyRole) {
    this.setupRole(role);
    this.open = true;
  }

  cancel() {
    this.open = false;
  }

  deleteUser() {
    this.$emit("delete", this.originalRole);
    this.open = false;
  }

  updateTeamMemberships(teamMemberships) {
    this.role.team_memberships = teamMemberships;
    this.updateEffectivePermissions();
  }

  updateExport(denyExport) {
    this.role.deny_export = denyExport;
  }

  updatePermissions(permissions) {
    this.role.permissions = permissions;
    this.updateEffectivePermissions();
  }

  updatePermittedReports(reports) {
    this.role.permitted_reports = reports;
  }

  updatePermittedDashboards(dashboards) {
    this.role.permitted_dashboards = dashboards;
  }

  updateEffectivePermissions() {
    this.role.effective_permissions = calculateEffectivePermissions(this.role, this.userTeams);
  }
}
</script>
