<template>
  <div class="users-list relative">
    <h3 class="hide-new-layout">{{ t("partner_admin.admin_users") }}</h3>
    <div class="flex-row mb-md">
      <div class="flex-1">
        <search-bar v-model="search" />
      </div>
      <div>
        <button @click="inviteUser" class="btn btn-primary">
          {{ t("partner_admin.invite_admin_user") }}
        </button>
      </div>
    </div>
    <spinner v-if="isFetching && !isFetched" />
    <admin-table :columns="columns" :items="users" :sort="sort" @update-sort="sort = $event">
      <template #cell(actions)="{ item }">
        <confirm-button @confirm="deleteUser(item)" :disabled="deleteUserMutation.isPending.value">
          <a class="text-underline">{{ t("actions.delete") }}</a>
        </confirm-button>
        <a v-if="item.invitation_key" class="text-underline" @click="resendInvitation(item)">{{
          t("actions.resend_invitation")
        }}</a>
      </template>
    </admin-table>
    <div v-if="totalPages > 1" class="centered">
      <uiv-pagination v-model="page" :total-page="totalPages"></uiv-pagination>
    </div>
    <admin-user-dialog ref="adminUserDialog" />
  </div>
</template>

<script setup lang="ts">
import { AdminTableColumn } from "@/admin/admin-table.vue";
import { useI18n } from "@/composables/i18n";
import { computed, Ref, ref } from "vue";
import Spinner from "@/components/spinner.vue";
import AdminTable from "@/admin/admin-table.vue";
import SearchBar from "@/components/search-bar.vue";
import {
  useDeleteUserPartnerRoleMutation,
  useUserPartnerRoles,
  UseUserPartnerRolesParams,
} from "@/partner-admin/admin-users/user-partner-roles-api";
import AdminUserDialog from "@/partner-admin/admin-users/admin-user-dialog.vue";
import ConfirmButton from "@/components/confirm-button.vue";
import { makeApiInstance } from "@/api/instance";
import UserPartnerRole from "@/model/user-partner-role";
import { useToastr } from "@/composables/toastr";

const { t } = useI18n();

const toaster = useToastr();

const search = ref("");

const sort = ref("last_name");

const page = ref(1);

const perPage = 15;

const totalPages = computed(() => (data.value ? Math.ceil(data.value.total / perPage) : 0));

const adminUserDialog = ref<typeof AdminUserDialog | null>(null);

const columns: AdminTableColumn[] = [
  { label: t("activerecord.attributes.user.first_name"), key: "first_name", sortable: true },
  { label: t("activerecord.attributes.user.last_name"), key: "last_name", sortable: true },
  { label: t("activerecord.attributes.user.email"), key: "email", sortable: true },
  { label: "", key: "actions" },
];

const params: Ref<UseUserPartnerRolesParams> = computed(() => ({
  page: page.value,
  per_page: perPage,
  sort: sort.value,
  search: search.value,
}));

const { isFetched, isFetching, data } = useUserPartnerRoles(params);

const users = computed(() => data.value?.data || []);

const inviteUser = () => {
  adminUserDialog.value?.show();
};

const deleteUserMutation = useDeleteUserPartnerRoleMutation();

const deleteUser = (user: any) => {
  deleteUserMutation.mutate(user.id);
};

const resendInvitation = async (role: UserPartnerRole) => {
  await makeApiInstance().post(`/api/partner_admin/user_partner_roles/${role.id}/resend_invitation`);
  toaster.success(t("partner_admin.invitation_resent"));
};
</script>
