<template>
  <div v-if="isVisible" class="report-owner-selector">
    <!-- Do not show this selector for team admins -->
    <div v-if="isSelectCompanyVisible || isSelectPartnerVisible" class="form-group">
      <div>
        <label>{{ t("newsletter.definition.visibility") }}</label>
      </div>
      <div class="btn-group">
        <div
          class="btn btn-primary"
          v-if="isSelectTeamVisible"
          :class="{ active: isTeamSelected }"
          @click="selectTeam()"
        >
          {{ t("newsletter.definition.team_members") }}
        </div>
        <div
          class="btn btn-primary"
          v-if="isSelectCompanyVisible"
          :class="{ active: isCompanySelected }"
          @click="selectCompany()"
        >
          {{ t("newsletter.definition.all_company_users") }}
        </div>
        <div
          class="btn btn-primary"
          v-if="isSelectPartnerVisible"
          :class="{ active: isPartnerSelected }"
          @click="selectPartner()"
        >
          {{ t("newsletter.definition.partner_customers", { partner: partner.name }) }}
        </div>
      </div>
    </div>
    <!-- Team selector, visible for team admins also -->
    <div class="form-group" v-if="isTeamSelected">
      <label>{{ t("newsletter.definition.team") }}</label>
      <select @change="onTeamChange">
        <option :value="team.id" :selected="newsletterDefinition.team_id == team.id" v-for="team in teams">{{
          team.name
        }}</option>
      </select>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "@/composables/i18n";
import TeamsApiService from "../api/teams-api-service";
import { computed, onMounted, reactive, ref } from "vue";
import NewsletterDefinition from "@/model/newsletter-definition";

const props = defineProps<{ newsletterDefinition: NewsletterDefinition }>();

const emit = defineEmits(["update-newsletter-definition"]);

const { t } = useI18n();

const teams = ref([]);

const isVisible = computed(() => {
  return isSelectTeamVisible.value || isSelectCompanyVisible.value || isSelectPartnerVisible.value;
});

const isSelectTeamVisible = computed(() => {
  return teams.value.length > 0;
});

const isSelectCompanyVisible = computed(() => {
  return isAdmin;
});

const isSelectPartnerVisible = computed(() => {
  return (isPartnerAdmin || isZoinedAdmin) && partner && props.newsletterDefinition.newsletter_type !== "alert";
});

const isAdmin = window.zoinedContext.isAdmin;

const isPartnerAdmin = window.zoinedContext.isPartnerAdmin;

const isZoinedAdmin = window.zoinedContext.isZoinedAdmin;

const companyId = window.zoinedContext.company.id;

const partner = window.zoinedContext.partner;

const isTeamSelected = computed(() => {
  return !!props.newsletterDefinition.team_id;
});

const isPartnerSelected = computed(() => {
  return !!props.newsletterDefinition.partner_id;
});

const isCompanySelected = computed(() => {
  return !isTeamSelected.value && !isPartnerSelected.value;
});

const selectCompany = () => {
  emit("update-newsletter-definition", {
    ...props.newsletterDefinition,
    partner_id: null,
    team_id: null,
    company_id: companyId,
  });
};

const selectPartner = () => {
  if (!partner) {
    return;
  }
  emit("update-newsletter-definition", {
    ...props.newsletterDefinition,
    partner_id: partner.id,
    company_id: null,
    team_id: null,
  });
};

const selectTeam = (team_id = null) => {
  team_id = team_id || teams.value[0]?.id;
  if (!team_id) {
    return;
  }
  emit("update-newsletter-definition", {
    ...props.newsletterDefinition,
    partner_id: null,
    team_id,
    company_id: companyId,
  });
};

const onTeamChange = (event) => selectTeam(event.target.value);

onMounted(() => {
  if (isAdmin) {
    new TeamsApiService().getTeams().then((result) => (teams.value = result));
  } else {
    // teams as admin
    teams.value = window.zoinedContext.current_role.team_memberships
      .filter(({ role }) => role === "admin")
      .map(({ team_id, name }) => ({ id: team_id, name }));
  }
});
</script>
