<!-- wraps bootstrap dropdown -->
<template>
  <div class="dropdown" ref="dropdown" :class="{ open }">
    <slot name="toggle" v-bind:toggleDropdown="toggle" v-bind:menuOpen="open"></slot>
    <div
      ref="dropdownMenu"
      class="dropdown-menu"
      v-if="open"
      :class="{ 'dropdown-menu-right': right }"
      :style="dropdownMenuStyle"
    >
      <slot name="menu" v-bind:toggleDropdown="toggle"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, provide, ref, watch } from "vue";
import { useEventListener, useWindowSize, useElementBounding } from "@vueuse/core";

defineProps<{
  right?: boolean;
}>();

const emit = defineEmits(["open", "close"]);

const open = ref(false);

const dropdown = ref(null);

const dropdownMenu = ref(null);

const parentDropdown = inject<{ close: Function }>("dropdown", null);

const dropdownMenuStyle = ref(null);

const windowSize = useWindowSize();

const menuBounding = useElementBounding(dropdownMenu);

const translateX = computed(() => {
  // Calculate translateX value to move menu to right or left if it's out of screen
  if (menuBounding.left.value < 0) {
    return -menuBounding.left.value + 10;
  } else if (menuBounding.right.value + 20 > windowSize.width.value) {
    return -(menuBounding.right.value - windowSize.width.value + 20);
  }
  return null;
});

watch(translateX, (value) => {
  // Translate menu to right or left if it's out of screen
  // We must do this in watch (not in computed) to avoid it recalculating infinitely
  // because element position will change (and trigger menuBounding change) when we apply translateX
  if (!dropdownMenuStyle.value && value) {
    dropdownMenuStyle.value = {
      transform: `translateX(${value}px)`,
    };
  }
});

const toggle = () => {
  open.value = !open.value;
};

const close = () => {
  open.value = false;
  parentDropdown?.close();
};

useEventListener(document, "click", (evt) => {
  if (open.value && !dropdown.value.contains(evt.target)) {
    close();
  }
});

provide("dropdown", {
  close,
});

watch(open, (value) => {
  if (value) {
    emit("open");
  } else {
    // Clear translateX style when closing (might open in different position next time)
    dropdownMenuStyle.value = null;

    emit("close");
  }
});
</script>

<style scoped>
.dropdown {
  display: inline-block;
}
</style>
