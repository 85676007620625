<template>
  <div class="ai-message flex-column gap-xxl pt-md" ref="el">
    <div class="align-self-flex-end bg-surface-neutral-2 rounded p-md ml-xxl">{{ aiMessage.message }}</div>
    <div class="align-self-flex-start bg-surface-neutral-1 rounded p-md mr-xxl relative">
      <div v-if="aiMessage.answer">
        {{ aiMessage.answer.message }}
      </div>
      <div v-else>
        <loading-dots />
      </div>
      <icon icon="fluent:sparkle-16-filled" class="absolute top-0 left-0 text-base -translate-1/2" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import AiMessage from "@/ai-assistant/ai-message";
import { Icon } from "@iconify/vue2";
import loadingDots from "@/components/loading-dots.vue";

const el = ref<HTMLElement | null>(null);

defineProps<{
  aiMessage: AiMessage;
}>();

onMounted(() => {
  setTimeout(() => {
    el.value.scrollIntoView({ behavior: "smooth", block: "start" });
  }, 100);
});
</script>

<style scoped>
.ai-message:last-child {
  min-height: 150px;
  @media screen and (min-width: 992px) {
    min-height: 100%;
  }
}
</style>
