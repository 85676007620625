<template lang="pug">
div
  #layout-container.custom-report-editor
    .row
      .col-md-4
        .form-group
          label(for="name") {{ "dashboard_custom.report_editor.title" | i18n }}
          input#name(type="text", v-model="report.metadata.title")
      .col-md-8
        .form-group
          label(for="description") {{ "dashboard_custom.report_editor.description" | i18n }}
          textarea#description(v-model="report.metadata.description")
    .row(v-if="isAdmin || isTeamAdmin")
      .col-md-12
        report-owner-selector(:my-report="myReport", @update-my-report="updateMyReport")
    .row(v-if="companyReport")
      .col-md-12
        .form-group
          div
            label(for="restricted") {{ "activerecord.attributes.my_report.restrict_access" | i18n }}
          .btn-group
            .btn.btn-primary(:class="{ active: myReport.restrict_access }", @click="setRestricted(true)") Yes
            .btn.btn-primary(:class="{ active: !myReport.restrict_access }", @click="setRestricted(false)") No
    .row(v-if="companyReport && myReport.restrict_access")
      .col-md-12
        user-role-pill-list(:roles="permittedRoles", @update="updatePermittedRoles($event)")
        team-pill-list(:teams="permittedTeams", @update="updatePermittedTeams($event)")
    .row(v-if="partnerReport")
      .col-md-12
        visible-to-customers-selector(v-model="myReport.visible_to_customers")

    .row
      .col-md-12
        h4 {{ "custom_report.report" | i18n }}

    .row
      .col-md-12
        .m-sm.type-item.component(
          v-for="reportType in reportTypes",
          :class="{ selected: reportType.config.report_type === selectedReportType.config.report_type }",
          @click="selectReportType(reportType.config.report_type)"
        )
          .chartImg(v-bind:class="reportType.config.report_type.replace(/[^a-zA-Z]/g, '')")
          p.type-text {{ ("dashboard_custom.report_editor.component_types." + reportType.config.report_type) | i18n }}
    .row
      .col-md-12
        h4 {{ "dashboard_custom.report_editor.filters" | i18n }}

    .row
      .col-md-12
        .time-filters
          time-period-dropdown(
            v-if="isFilterEnabled('date')",
            :available-items="options.time_ranges",
            :available-series-types="options.seriesTypes",
            :value="filterConfiguration.time.selection",
            @update="updateSelection($event)"
          )
          comparison-pill-list(
            v-if="isFilterEnabled('comparisons')",
            :radio="isRadio('comparisons')",
            :comparisons="filterConfiguration.time.comparisons",
            @update="updateComparisons"
          )
          business-hours-selector(
            v-if="isFilterEnabled('limit_to_business_hours')",
            :value="filterConfiguration.time.limit_to_business_hours",
            @update="updateBusinessHours($event)"
          )
        widget-filters-section(
          :filter-configuration="filterConfiguration",
          :report-config="selectedReportType.config",
          @update="updateFilterConfiguration"
        )
        .normal-filters
          filterset-selector(:config="filterConfiguration.filters", @update="updateFiltersConfig")
          show-filterset-name-toggle(v-model="myReport.control_state.show_filterset_name", v-if="hasEnabledFilterSets")
    .row
      .col-md-12
        .filter-container
          filter-selector(
            :config="filters[filter.id]",
            :filter="filter",
            @update="updateFilter(filter.id, $event)",
            v-for="filter in options.filters",
            :key="filter.id"
          )
    .row
      .col-md-12
        h4 {{ "actions.preview" | i18n }}

    div(v-if="valid")
      .row
        .col-md-12.mb-md.flex-row.justify-content-flex-end
          report-controls(
            :controls="controls",
            :filter-configuration="filterConfiguration",
            :chart-options="chartOptions",
            :table-config="tableConfig",
            @update-filter-configuration="updateFilterConfiguration",
            @update-chart-options="updateChartOptions",
            @update-table-config="updateTableConfig"
          )

      .row.mb-xl(v-for="component in report.config.components")
        div(:class="'col-md-' + (component.span || 12)")
          zoined-report-component(
            :component="component",
            :filter-configuration="flatFilterConfiguration",
            :chart-options="chartOptions",
            :table-config="tableConfig",
            :highchart-options="highchartOptions",
            :custom="true",
            :dashboard="false",
            @filter-configuration-updated="updateFilterConfiguration",
            @highchart-options-updated="updateHighchartOptions",
          )
    .help-block(v-else-if="report.config.help_key")
      i.fa.fa-info-circle.mr-sm
      span {{ ("report.help." + report.config.help_key) | i18n }}

    .row.buttons
      .col-md-12
        .pull-left.hide-new-layout
          confirm-button.delete-dashboard(
            v-if="report.id && myReport.removable",
            :button-title="'custom_report.delete_report' | i18n",
            variant="danger",
            @confirm="deleteDashboard()"
          )
        .pull-right
          button.pull-right.btn.btn-primary(@click="save", :disabled="submitDisabled") {{ "actions.save" | i18n }}
</template>

<script lang="ts">
import Vue from "vue";
import _ from "lodash";
import {
  refreshFilterTimes,
  convertToNewFilterConfiguration,
  flatFilterConfiguration,
  filterToFlyover,
  mergeFilterConfigurations,
} from "../lib/filter-util";
import confirmButton from "../components/confirm-button.vue";
import userRolePillList from "../components/user-role-pill-list.vue";
import teamPillList from "../components/team-pill-list.vue";
import comparisonPillList from "../components/comparison-pill-list.vue";
import filterSelector from "../components/filter-selector.vue";
import timePeriodDropdown from "../components/time-period-dropdown.vue";
import businessHoursSelector from "../components/business-hours-selector.vue";
import filtersetSelector from "../components/filterset-selector.vue";
import showFiltersetNameToggle from "../components/show-filterset-name-toggle.vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import FilterConfigurationItemMap from "../model/filter-configuration-item-map";
import { reportTypes } from "./report-types";
import zoinedReportComponent from "../analytics/zoined-report-component.vue";
import WidgetFiltersSection from "../flyover-filters/widget-filters-section.vue";
import FilterConfiguration from "../model/filter-configuration";
import i18n from "../i18n";
import ReportOwnerSelector from "../components/report-owner-selector.vue";
import MyReport from "../model/my-report";
import Actions from "../store/actions";
import ChartOptions from "../model/chart-options";
import TableConfig from "../model/table-config";
import ReportControls from "../components/report-controls.vue";
import { makeApiInstance } from "../api/instance";
import { hideOverlay, showOverlay } from "@/ui/overlay";
import visibleToCustomersSelector from "@/components/visible-to-customers-selector.vue";

@Component({
  components: {
    confirmButton,
    userRolePillList,
    teamPillList,
    comparisonPillList,
    filterSelector,
    timePeriodDropdown,
    businessHoursSelector,
    filtersetSelector,
    showFiltersetNameToggle,
    zoinedReportComponent,
    WidgetFiltersSection,
    ReportOwnerSelector,
    ReportControls,
    visibleToCustomersSelector,
  },
})
export default class CustomDashboardEditor extends Vue {
  @Prop()
  defaultReport: any;

  @Prop()
  defaultMyReport: any;

  report: any = null;
  myReport: any = null;
  permittedRoles: any = null;
  permittedTeams: any = null;
  isAdmin: boolean = null;
  selectedReportType = null;
  initialData = null;

  get companyReport() {
    return !this.myReport.user_id && !this.myReport.team_id && !this.myReport.partner_id;
  }

  get partnerReport() {
    return !!this.myReport.partner_id;
  }

  get teamsAsAdmin() {
    return window.zoinedContext.current_role.team_memberships.filter(({ role }) => role === "admin");
  }

  get isTeamAdmin() {
    return this.teamsAsAdmin.length > 0;
  }

  get submitDisabled() {
    return this.report.metadata.title.length == 0 || !this.valid;
  }

  get reportTypes() {
    return reportTypes.filter((reportType) => {
      return _.every(reportType.config.required_features, (feature) => window.zoinedContext.features[feature]);
    });
  }

  get flatFilterConfiguration() {
    return flatFilterConfiguration(this.filterConfiguration);
  }

  get filterConfiguration() {
    return _.cloneDeep(this.myReport.filters);
  }

  get chartOptions() {
    return _.cloneDeep(this.myReport.control_state);
  }

  get tableConfig() {
    return _.cloneDeep(this.myReport.table_config);
  }

  get highchartOptions() {
    return _.cloneDeep(this.myReport.highchart_options);
  }

  get options() {
    const sort = this.$store.getters.getParameters("sort") || [];
    const seriesTypes = window.zoinedContext?.budgets && ["actual", ...Object.keys(window.zoinedContext.budgets)];
    const groupings = _.clone(this.$store.getters.getParameters("grouping"));
    const metrics = this.$store.state.parameters.metrics.all;
    const snippets = this.$store.state.parameters.metrics.all?.map((item) => ({ ...item, snippet: true }));
    const filters = this.$store.state.parameters.filters.all;
    const time_ranges = this.$store.state.parameters.timePeriods.all;

    return {
      metrics: metrics || [],
      snippets: snippets || [],
      time_ranges: time_ranges || [],
      filters: filters || [],
      sort,
      seriesTypes,
      groupings,
    };
  }

  get filters() {
    return _.reduce(
      this.filterConfiguration.filters.filters,
      (result, config, filter) => {
        return {
          ...result,
          [filter]: _.fromPairs(
            _.map(config, ({ enabled, exclude, value, name }, key) => [
              key,
              {
                value: key,
                enabled,
                exclude: !!exclude,
                name: name || value || key,
              },
            ])
          ),
        };
      },
      {}
    );
  }

  get hasEnabledFilterSets() {
    let { sets } = this.filterConfiguration.filters;
    sets = sets || [];
    return sets.filter(({ enabled }) => enabled).length > 0;
  }

  get valid(): boolean {
    const validate = this.selectedReportType.validate || (() => true);
    return validate(this.flatFilterConfiguration);
  }

  get controls() {
    return this.report.config.controls || {};
  }

  get saveData() {
    if (!this.report) {
      return null;
    }
    return _.cloneDeep({
      report: {
        ...this.report,
        config: JSON.stringify(this.report.config),
      },
      my_report: {
        filters: this.myReport.filters,
        restrict_access: this.myReport.restrict_access,
        control_state: this.myReport.control_state,
        table_config: this.myReport.table_config,
        highchart_options: this.myReport.highchart_options,
        permitted_role_ids: this.myReport.restrict_access ? this.permittedRoles.map(({ id }) => id) : [],
        permitted_team_ids: this.myReport.restrict_access ? this.permittedTeams.map(({ id }) => id) : [],
        visible_to_customers: this.myReport.visible_to_customers,
        user_id: this.myReport.user_id,
        team_id: this.myReport.team_id,
        partner_id: this.myReport.partner_id,
        company_id: this.myReport.company_id,
      },
    });
  }

  get hasChanges() {
    return this.initialData && !_.isEqual(this.initialData, this.saveData);
  }

  get isNew() {
    return !this.report.id;
  }

  selectReportType(type) {
    this.selectedReportType =
      reportTypes.find((reportType) => reportType.config.report_type === type) || reportTypes[0];
    this.report.config = this.selectedReportType.config;

    // some sensible global defaults
    const defaults: any = filterToFlyover({
      selection: { type: "4_weeks" },
      comparisons: [{ type: "prev_year_corresponding", enabled: true }],
      metrics: { sales: { enabled: true } },
      grouping: { store: { enabled: true } },
    });

    if (this.isNew) {
      defaults.column_grouping = { weekday: { enabled: true } };
    }

    //  defaults for selected type
    const reportTypeDefaults = filterToFlyover(
      Object.keys(this.selectedReportType.filters || {}).reduce((defaults, key) => {
        const defaultValue = _.get(this.selectedReportType.filters[key], "default");
        return defaultValue
          ? {
              ...defaults,
              [key]: _.isFunction(defaultValue) ? defaultValue(this.flatFilterConfiguration) : defaultValue,
            }
          : defaults;
      }, this.selectedReportType.defaults || {})
    );

    // Get names of active filters. Some filters might have some default value set.
    const activeFilters = _.union(this.report.config.filters, Object.keys(this.selectedReportType.defaults || {}));

    if (activeFilters.includes("date")) {
      // Filter key for date is selection (not date)
      activeFilters.push("selection");
    }

    // remove filters that are not used for this report type and set correct defaults
    Vue.set(
      this.myReport,
      "filters",
      Object.assign({}, this.myReport.filters, {
        time: _.pick({ ...defaults.time, ...this.myReport.filters.time, ...reportTypeDefaults.time }, activeFilters),
        widgets: _.pick(
          { ...defaults.widgets, ...reportTypeDefaults.widgets, ...this.myReport.filters.widgets },
          activeFilters
        ),
        raw_filters: _.pick(
          { ...defaults.raw_filters, ...reportTypeDefaults.raw_filters, ...this.myReport.filters.raw_filters },
          activeFilters
        ),
      })
    );
  }

  setRestricted(restricted) {
    Vue.set(this.myReport, "restrict_access", restricted);
  }

  isFilterEnabled(filter) {
    return this.report.config.filters?.includes(filter);
  }

  isRadio(filter) {
    return this.report.config.radio_selectors?.includes(filter);
  }

  updateSelection(selection) {
    this.myReport.filters.time.selection = selection;
    this.myReport.filters.time = refreshFilterTimes(this.myReport.filters.time);
  }

  updateComparisons(comparisons) {
    Vue.set(this.myReport.filters.time, "comparisons", comparisons);
  }

  updateBusinessHours(limitToBusinessHours) {
    Vue.set(this.myReport.filters.time, "limit_to_business_hours", limitToBusinessHours);
  }

  updateFiltersConfig(config) {
    Vue.set(this.myReport.filters, "filters", config);
  }

  updateMyReport(myReport: MyReport) {
    Vue.set(this, "myReport", myReport);
  }

  updateFilter(filter, filterConfig: FilterConfigurationItemMap) {
    if (!_.isEmpty(filterConfig)) {
      const config = _.reduce(
        _.values(filterConfig),
        (result, { enabled, exclude, value, name, wildcard }) => ({
          ...result,
          [value]: { enabled, exclude: !!exclude, value, wildcard, name: name || value },
        }),
        {}
      );
      Vue.set(this.myReport.filters.filters.filters, filter, config);
    } else {
      Vue.delete(this.myReport.filters.filters.filters, filter);
    }
  }

  updateFilterConfiguration(filterConfiguration: FilterConfiguration) {
    filterConfiguration = convertToNewFilterConfiguration(filterConfiguration);
    Vue.set(this.myReport, "filters", filterConfiguration);
  }

  updateChartOptions(chartOptions: ChartOptions) {
    Vue.set(this.myReport, "control_state", chartOptions);
  }

  updateTableConfig(tableConfig: TableConfig) {
    Vue.set(this.myReport, "table_config", tableConfig);
  }

  updateHighchartOptions(highchartOptions) {
    Vue.set(this.myReport, "highchart_options", highchartOptions);
  }

  updatePermittedRoles(roles) {
    this.permittedRoles = roles;
  }

  updatePermittedTeams(teams) {
    this.permittedTeams = teams;
  }

  save() {
    if (!this.valid) {
      return;
    }
    showOverlay(i18n.t("please_wait"));
    const url = this.report.id ? `/api/v1/reports/${this.report.id}` : `/api/v1/reports`;
    const method = this.report.id ? "PUT" : "POST";
    const data = (this.initialData = this.saveData);
    return makeApiInstance()
      .request({
        url,
        method,
        data,
      })
      .then((response) => response.data)
      .then(({ id }) => {
        this.$router.push({ name: "custom_report", params: { id } });
        this.$store.dispatch(Actions.fetchNavigation);
      })
      .finally(() => {
        hideOverlay();
      });
  }

  deleteDashboard() {
    const url = `/api/v1/reports/${this.report.id}`;
    return makeApiInstance()
      .delete(url)
      .then(() => {
        window.location.href = "/";
      });
  }

  created() {
    const report = _.cloneDeep(this.defaultReport);
    const myReport = _.cloneDeep(this.defaultMyReport);
    const permittedRoles = myReport.permitted_roles || [];
    const permittedTeams = myReport.permitted_teams || [];
    delete report.permitted_roles;

    myReport.restrict_access = !!myReport.restrict_access;
    myReport.control_state = myReport.control_state || {};

    const defaultConfig = {
      selection: { type: "4_weeks" },
      comparisons: [{ type: "prev_year_corresponding", enabled: true }],
    };
    const filterConfigurations = _.compact([
      defaultConfig,
      report.config.filterConfiguration,
      myReport.filters,
    ]).map((config) => convertToNewFilterConfiguration(config));
    myReport.filters = mergeFilterConfigurations(...filterConfigurations);
    myReport.filters.time = refreshFilterTimes(myReport.filters.time);
    delete report.config.filterConfiguration;

    this.report = report;
    this.myReport = myReport;
    this.isAdmin = !!window.zoinedContext?.isAdmin;

    this.permittedRoles = permittedRoles;
    this.permittedTeams = permittedTeams;

    this.selectReportType(this.report.config.report_type);
    this.initialData = this.saveData;
  }
}
</script>

<style scoped lang="scss">
#layout-container {
  margin-bottom: 8rem; // need to have space for the Olark-tab in bottom of the page
}

.buttons {
  margin-top: 30px;
}

.show {
  text-align: center;

  a:hover {
    cursor: pointer;
  }
}

.time-filters,
.widget-filters {
  margin-bottom: 10px;
}

.row h4 {
  margin: 20px 0;
}
</style>
