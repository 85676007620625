<template>
  <div class="email-deliveries-list relative">
    <h3 class="hide-new-layout">{{ t("email_deliveries.title") }}</h3>
    <div class="table-controls mb-md">
      <search-bar v-model="search" />
    </div>
    <spinner v-if="isFetching && !isFetched" />
    <admin-table :columns="columns" :items="emailDeliveries" :sort="sort" @update-sort="sort = $event">
      <template #cell(name)="{ item }">
        {{ userName(item) }}
      </template>
      <template #cell(company)="{ item }">
        {{ companyName(item) }}
      </template>
      <template #cell(last_bounce_at)="{ value }">
        {{ dateTime(value) }}
      </template>
      <template #cell(status)="{ item }">
        {{ item.blocked ? "Blocked" : "Active" }}
      </template>
      <template #cell(actions)="{ item }">
        <a href="#" style="text-decoration: underline;" @click="clearDelivery(item)">Clear</a>
      </template>
    </admin-table>
    <div v-if="totalPages > 1" class="centered">
      <uiv-pagination v-model="page" :total-page="totalPages"></uiv-pagination>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "@/composables/i18n";
import {
  useClearEmailDeliveryMutation,
  useEmailDeliveries,
} from "@/zoined-admin/email-deliveries/email-deliveries-api";
import SearchBar from "@/components/search-bar.vue";
import { computed, ref } from "vue";
import Spinner from "@/components/spinner.vue";
import { dateTime } from "@/lib/vue/filters/date";
import AdminTable, { AdminTableColumn } from "@/admin/admin-table.vue";

const { t } = useI18n();

const search = ref("");

const sort = ref("-last_bounce_at");

const page = ref(1);

const perPage = 15;

const columns: AdminTableColumn[] = [
  { label: "Email", key: "email", sortable: true },
  { label: "Name", key: "name" },
  { label: "Company", key: "company" },
  { label: "Bounce reason", key: "bounce_reason" },
  { label: "Bounce count", key: "bounce_count" },
  { label: "Last bounce", key: "last_bounce_at", sortable: true },
  { label: "Status", key: "status" },
  { label: "Actions", key: "actions" },
];

const params = computed(() => {
  return {
    search: search.value,
    page: page.value,
    per_page: perPage,
    sort: sort.value,
  };
});

const { isFetching, isFetched, data } = useEmailDeliveries(params);

const emailDeliveries = computed(() => data.value?.data || []);

const totalPages = computed(() => (data.value ? Math.ceil(data.value.total / perPage) : 0));

const clearDeliveryMutation = useClearEmailDeliveryMutation();

const clearDelivery = (delivery) => {
  clearDeliveryMutation.mutate(delivery.id);
};

const userName = (delivery) => {
  return delivery.user ? `${delivery.user?.first_name} ${delivery.user?.last_name}` : "Unknown";
};

const companyName = (delivery) => {
  return (
    delivery.user?.companies
      ?.map((company) => {
        return company.name + (company.partner?.name ? ` (${company.partner.name})` : "");
      })
      .join(", ") || "Unknown"
  );
};
</script>

<style lang="scss" scoped>
.email-deliveries-list {
  min-height: 200px;
}
</style>
