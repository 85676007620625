<template lang="pug">
.pill-button(:class="{inactive: !enabled, exclude, 'button-style': buttonStyle, 'pill-outline': pillOutline }" @click="$emit('toggle')")
  slot {{ label }}
  span.pill-button-del(@click.stop="$emit('remove')", v-if="removable")
    i.fa.fa-circle.border
    i.fa.fa-times-circle
</template>

<script>
export default {
  props: {
    enabled: {
      type: Boolean,
      default: true,
    },
    removable: {
      type: Boolean,
      default: true,
    },
    label: String,
    exclude: {
      type: Boolean,
      default: false,
    },
    pillStyle: {
      type: String,
      default: "pill",
    },
  },
  computed: {
    buttonStyle() {
      return this.pillStyle == "button";
    },
    pillOutline() {
      return this.pillStyle == "pill-outline";
    },
  },
};
</script>

<style lang="scss" scoped>
.pill-button {
  display: inline-block;
  color: #fff;
  position: relative;
  padding: 4px 16px;
  border-radius: 17px;
  font-size: 0.9em;
  font-weight: 400;
  margin: 0 7px 5px 0;

  &.button-style {
    padding: 5px 16px;
    border-radius: 5px;
  }

  &.pill-outline {
    background-color: transparent;
    border: 1px solid #4bcdf0;
    color: #2aaacd;

    &.inactive {
      background-color: transparent;
      border-color: #84827d;
      color: #dadad9;
    }
  }

  &.inactive,
  &.inactive.exclude:hover {
    color: #73726e;
    border-color: #a4a29c;
    background-color: #a4a29c;
  }

  .pill-button-del {
    position: absolute;
    top: -1px;
    right: -12px;
    display: none;
    width: 1em;
    height: 1em;
    font-size: 170%;
    i {
      position: absolute;
      top: 0;
      left: 0;
    }
    .fa-circle.border {
      color: #fff;
      font-size: 70%;
      top: 10%;
      left: 10%;
    }
    .fa-times-circle {
      color: #333436;
    }
    &:hover {
      display: block;
      .fa-circle.border {
        color: #333436;
      }
      .fa-times-circle {
        color: darken(#fff, 8%);
      }
    }
  }
  &:hover .pill-button-del {
    display: inline-block;
  }
}
</style>
